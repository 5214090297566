






































































































import { Vue, Component, Prop } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import { AdminBaseItem } from "@/types/admin";
import { DataTableHeader } from "vuetify/types/";
import { getFileObjectByUrl } from "@/utils/filehelper";
import AdminBaseItemCRUDDialog from "./AdminBaseItemCRUDDialog.vue";

/**
 * AdminBaseItemTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemCRUDDialog,
    },
})
export default class AdminBaseItemTable<
    ItemType extends AdminBaseItem
> extends Vue {
    // Table props
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: Array, required: true }) readonly items!: ItemType[];
    @Prop({ type: Array, required: true }) readonly headers!: DataTableHeader[];
    @Prop({ type: String, default: "id" }) readonly itemKey!: string;

    // crud dialog props
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Object, default: () => ({}) })
    readonly defaultCRUDItem!: ItemType;
    @Prop({ type: String, required: false }) readonly crudPermissionSubject:
        | string
        | undefined;
    @Prop({ type: String, required: false }) readonly crudURLPrefix:
        | string
        | undefined;

    private searchText = "";

    private isCRUDDialogVisible = false;
    private crudDialogMode = "create";
    private currentCRUDItem = this.defaultCRUDItem;
    private currentCRUDItemIndex = -1;

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get crudTableHeaders(): DataTableHeader[] {
        const crudTableHeaders = this.headers.slice();

        if (this.editable) {
            crudTableHeaders.push({
                value: "actions",
                text: this.$t("admin.crudTable.header.action").toString(),
                sortable: false,
                filterable: false,
            });
        }

        return crudTableHeaders;
    }

    /**
     * Sets currentCRUDItem, currentCRUDItemIndex, dialogMode variables.
     * Fetches image object(s) when exists
     * Displays crud dialog
     */
    private async showCRUDDialog(
        dialogMode: string,
        item?: ItemType
    ): Promise<void> {
        try {
            // dialogMode = update/delete
            if (typeof item !== "undefined") {
                // if dialogMode = update and image(s) is set, fetch image(s)
                if (dialogMode === "update") {
                    if (
                        item.image != undefined &&
                        !(item.image.fileObject instanceof File)
                    ) {
                        item.image.fileObject = await getFileObjectByUrl(
                            item.image.src
                        );
                    } else if ("images" in item && Array.isArray(item.images)) {
                        for (let i = 0; i < item.images.length; i++) {
                            if (!(item.images[i].fileObject instanceof File)) {
                                item.images[i].fileObject =
                                    await getFileObjectByUrl(
                                        item.images[i].src
                                    );
                            }
                        }
                    }
                }

                this.currentCRUDItem = { ...item };
                this.currentCRUDItemIndex = this.items.indexOf(item);
            }
            // dialogMode = create
            else {
                this.currentCRUDItem = { ...this.defaultCRUDItem };
                this.currentCRUDItemIndex = -1;
            }

            this.crudDialogMode = dialogMode;
            this.isCRUDDialogVisible = true;
            this.$emit(
                "crudDialogOpen",
                this.crudDialogMode,
                this.currentCRUDItem,
                this.currentCRUDItemIndex
            );
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Handler for crud dialog save event
     *
     * @param newItem new/updated/deleted item
     * @param dialogMode dialog mode
     * @param oldItem old item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogSaved(
        newItem: ItemType,
        dialogMode: string,
        oldItem: ItemType
    ) {
        const updatedItems = this.items.slice();

        switch (this.crudDialogMode) {
            case "create":
                this.currentCRUDItemIndex = updatedItems.push(newItem) - 1;
                break;
            case "update":
                Object.assign(updatedItems[this.currentCRUDItemIndex], newItem);
                break;
            case "delete":
                updatedItems.splice(this.currentCRUDItemIndex, 1);
                break;
        }

        this.$emit(
            "crudDialogSave",
            updatedItems,
            dialogMode,
            oldItem,
            this.currentCRUDItemIndex
        );
    }

    /**
     * Handler for crud dialog close event
     * Emits crudDialogClose event
     *
     * @param dialogMode dialog mode
     * @param item crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogClosed(dialogMode: string, item: ItemType) {
        this.$emit(
            "crudDialogClose",
            dialogMode,
            item,
            this.currentCRUDItemIndex
        );
    }
}
