/**
 * Fetches the blob from the given url and parse it to a JavaScript File Object
 *
 * @param url url
 * @param fileName file name (optional)
 *
 * @returns Promise<File>
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export async function getFileObjectByUrl(url: string, fileName?: string) {
    return fetch(url).then(async (response) => {
        const blob = await response.blob();

        if (fileName === undefined) {
            fileName = url.split("/").slice(-1)[0];
        }

        return new File([blob], fileName, { type: blob.type });
    });
}
