













































import { Component, Vue, Prop } from "vue-property-decorator";
import { AdminBaseItem } from "@/types/admin";
import { Image } from "@/types/image";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alert";

/**
 * AdminBaseCRUDDialog component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class AdminBaseItemCRUDDialog<
    ItemType extends AdminBaseItem
> extends Vue {
    @Prop({ type: Boolean, required: true }) readonly value!: boolean;
    @Prop({ type: String, required: true }) readonly mode!: string;
    @Prop({ type: Object, required: true }) readonly item!: ItemType;
    @Prop({ type: String, required: false }) readonly urlPrefix:
        | string
        | undefined;

    private formValid = false;

    /**
     * @returns the title for the form
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return this.$t(
            "admin.crudDialog.title." + this.mode + "Item"
        ).toString();
    }

    /**
     * Resets the form validation
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        // @ts-ignore
        if (typeof this.$refs?.form?.resetValidation === "function") {
            (
                this.$refs.form as Vue & {
                    resetValidation: () => void;
                }
            ).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async saveItem() {
        try {
            // if dialogMode != delete and image(s) is set, save image(s)
            if (this.mode !== "delete") {
                if ("image" in this.item && this.item.image != undefined) {
                    this.item.image = await this.saveItemImage(this.item.image);
                } else if (
                    "images" in this.item &&
                    Array.isArray(this.item.images)
                ) {
                    for (let i = 0; i < this.item.images.length; i++) {
                        this.item.images[i] = await this.saveItemImage(
                            this.item.images[i]
                        );
                    }
                }
            }
            let itemResponse = this.item;
            // if urlPrefix is set send item crud request
            if (this.urlPrefix) {
                const apiUrl =
                    this.urlPrefix +
                    "/" +
                    this.mode +
                    (this.mode !== "create" ? `/${this.item.id}` : "");

                const apiData = this.mode !== "delete" ? this.item : undefined;
                const response = (
                    await axios.post<APIResponse<ItemType>>(apiUrl, apiData)
                ).data;
                if (response.status === "error") {
                    throw new Error(response.message || "unkownError");
                }

                // set itemResponse to api response
                itemResponse = response.data;
            }

            this.$emit("save", itemResponse, this.mode, this.item);
            this.closeDialog();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Saves/Updates or deletes the item image
     *
     * @returns Promise<Image>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async saveItemImage(image: Image): Promise<Image> {
        const imageCRUDType = image.id === -1 ? "create" : this.mode;
        const apiUrl =
            "/image/" +
            imageCRUDType +
            (imageCRUDType !== "create" ? `/${image.id}` : "");

        let apiFormData = undefined;
        let apiHeaders = {};
        if (imageCRUDType !== "delete") {
            if (!image.fileObject) {
                throw new Error("noImageSelected");
            }

            apiFormData = new FormData();
            apiFormData.append("file", image.fileObject);
            apiFormData.append("alt", image.alt);

            apiHeaders = {
                "Content-Type": "multipart/form-data",
            };
        }

        const response = (
            await axios.post<APIResponse<Image>>(
                apiUrl,
                apiFormData,
                apiHeaders
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unkownError");
        }

        return { ...response.data, fileObject: image.fileObject };
    }

    /**
     * Closes the dialog and emits input and close event
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit("input", false);
        this.$emit("close", this.mode, this.item);
    }
}
