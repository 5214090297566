var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('admin.crudTable.search')},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-card-subtitle',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(
                        _vm.editable &&
                        (_vm.crudPermissionSubject === undefined ||
                            _vm.$can('create', _vm.crudPermissionSubject))
                    )?_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.showCRUDDialog('create')}}},[_vm._v(_vm._s(_vm.$t("admin.crudTable.createItem")))]):_vm._e()],1)],1)],1),_c('v-card-text',[(_vm.editable)?_c('AdminBaseItemCRUDDialog',{attrs:{"mode":_vm.crudDialogMode,"item":_vm.currentCRUDItem,"urlPrefix":_vm.crudURLPrefix},on:{"close":_vm.crudDialogClosed,"save":_vm.crudDialogSaved},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var item = ref.item;
                    var mode = ref.mode;
return [_vm._t("crud-form",null,{"item":item,"itemIndex":_vm.currentCRUDItemIndex,"dialogMode":mode})]}}],null,true),model:{value:(_vm.isCRUDDialogVisible),callback:function ($$v) {_vm.isCRUDDialogVisible=$$v},expression:"isCRUDDialogVisible"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.crudTableHeaders,"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":5,"search":_vm.searchText,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(
                        _vm.crudPermissionSubject === undefined ||
                        _vm.$can('update', _vm.crudPermissionSubject)
                    )?_c('v-icon',{attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.showCRUDDialog('update', item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(
                        _vm.crudPermissionSubject === undefined ||
                        _vm.$can('delete', _vm.crudPermissionSubject)
                    )?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.showCRUDDialog('delete', item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},_vm._l((_vm.$scopedSlots),function(slotContent,slotName){return {key:slotName,fn:function(item){return [_vm._t(slotName,null,null,item)]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }